import ClearIcon from '@mui/icons-material/Clear'
import {Button, Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {getTranslationKey} from '../../utils/translation.utils'

type RemoveFilter = {
  removeFilter?: (keyName: string) => void
  keyName: string
  translationPrefix?: string
}

export const RemoveFilterButton: React.FC<RemoveFilter> = (props) => {
  const {t} = useTranslation()
  const {removeFilter, keyName} = props
  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Button
        sx={{
          border: '1px solid rgba(31, 73, 94, 0.1)',
          margin: '10px 0'
        }}
        variant="text"
        onClick={() => removeFilter?.(keyName)}
        startIcon={<ClearIcon />}
        data-test-id={`remove-filter-button-${keyName}`}
      >
        {t(getTranslationKey('shiftEvent.label.removeFilter', props.translationPrefix))}
      </Button>
    </Box>
  )
}
