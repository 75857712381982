import {useCallback} from 'react'
import {useEvent} from 'react-use'

export type ArrowKey = 'up' | 'down'

// use memoized handler to prevent event register/unregister each render
export const useArrowKeys = (handler: (key: ArrowKey) => void) => {
  const handleKeyDown = useCallback(
    (e: Event) => {
      if (!(e instanceof KeyboardEvent)) {
        return
      }
      switch (e.key) {
        case 'ArrowUp':
          handler('up')
          break
        case 'ArrowDown':
          handler('down')
          break
        default:
          return
      }

      e.preventDefault()
    },
    [handler]
  )

  useEvent(
    'keydown',
    handleKeyDown,
    window,
    {capture: true} // use capture phase of propagation to be able to prevent other scrollers to react to arrow keys
  )
}

export const scrollToEvent = (arrow: ArrowKey, eventIndex: number) => {
  const container = document.querySelector<HTMLElement>("[data-test-id='events-table-container']")
  const row = document.querySelector<HTMLElement>(`[aria-label='DataTable row ${eventIndex}']`)

  if (row && container) {
    const rowHeight = row.offsetHeight

    if (arrow === 'up') {
      if (
        row.getBoundingClientRect().top < container.getBoundingClientRect().top + rowHeight ||
        row.getBoundingClientRect().top > container.getBoundingClientRect().bottom
      ) {
        container.scrollTop = row.offsetTop - rowHeight
      }
    } else {
      if (row.getBoundingClientRect().bottom > container.getBoundingClientRect().bottom) {
        container.scrollTop = row.offsetTop - container.offsetHeight + rowHeight
      }
      if (eventIndex === 0 && row) {
        container.scrollTop = 0
      }
    }
  }
}
